import React, { useState } from 'react';
import { FaUser, FaCalendarAlt, FaIdCard } from 'react-icons/fa';
import './StudentForm.css';
import { useNavigate } from 'react-router-dom';

function StudentForm() {
    const [fullName, setFullName] = useState('');
    const [dob, setDob] = useState('');
    const [applicationNumber, setApplicationNumber] = useState('');
    const [dobError, setDobError] = useState('');
    const [appNumberError, setAppNumberError] = useState(''); // State for application number error
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const formatDate = (inputDate) => {
        const dateParts = inputDate.split('-');
        return `${dateParts[0]}-${dateParts[1].padStart(2, '0')}-${dateParts[2].padStart(2, '0')}`;
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        const dobFormatted = formatDate(dob); // Format DOB to YYYY-MM-DD

        const dobRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
        if (!dobRegex.test(dobFormatted)) {
            setDobError('Date of Birth must be in YYYY-MM-DD format.');
            return;
        }
        setDobError('');

        const selectedQuizId = localStorage.getItem('selectedQuizId'); // Fetch selected quiz ID from localStorage
        const token = localStorage.getItem('token'); // Fetch token from localStorage
        setLoading(true);

        const payload = {
            fullName,
            applicationNumber,
            DOB: dobFormatted, // Use formatted DOB
            AllquestionId: selectedQuizId // Include the selected quiz ID in the payload
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': token // Replace with actual token
        };

        try {
            const response = await fetch('https://598sj81enf.execute-api.ap-south-1.amazonaws.com/v1/studentFormDetails_M', {
                method: 'POST',
                headers,
                body: JSON.stringify(payload)
            });

            if (response.status === 201) {
                const data = await response.json();
                localStorage.setItem('applicationnumber', applicationNumber);
                console.log(data.message); // Handle success response

                // Call the SuffledQuestionUser API
                const shuffledPayload = {
                    id: selectedQuizId,
                    applicationNumber: applicationNumber
                };

                const shuffledResponse = await fetch('https://598sj81enf.execute-api.ap-south-1.amazonaws.com/v1/SuffledQuestionUser_M', {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(shuffledPayload)
                });

                if (shuffledResponse.status === 200) {
                    setLoading(false);
                    const shuffledData = await shuffledResponse.json();
                    try {
                        localStorage.setItem('shuffledQuestions', JSON.stringify(shuffledData));
                        localStorage.setItem('quizId', selectedQuizId);
                        console.log('Shuffled Data:', shuffledData);
                        navigate('/instructionsPage');
                    } catch (error) {
                        console.error('Error storing in localStorage:', error);
                    }
                } else {
                    console.log('Unexpected response from SuffledQuestionUser API:', shuffledResponse.status);
                }

            } else if (response.status === 400) {
                setAppNumberError('Application number already exists. Please use another one.');
                setLoading(false);
            } else {
                console.log('Unexpected response from studentFormDetails API:', response.status);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleLogin} className="login-form">
                <h2 className="login-header">Student Form</h2>

                <div className="form-group">
                    <label htmlFor="fullName"><FaUser className="form-icon" /> Full Name</label>
                    <input
                        type="text"
                        id="fullName"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        placeholder="Enter Full Name"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="dob"><FaCalendarAlt className="form-icon" /> Date of Birth</label>
                    <input
                        type="text"
                        id="dob"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        placeholder="Enter Date of Birth (YYYY-MM-DD)"
                        required
                    />
                    {dobError && <span className="erroremail-message">{dobError}</span>}
                </div>

                <div className="form-group">
                    <label htmlFor="applicationNumber"><FaIdCard className="form-icon" /> Application Number</label>
                    <input
                        type="text"
                        id="applicationNumber"
                        value={applicationNumber}
                        onChange={(e) => setApplicationNumber(e.target.value)}
                        placeholder="Enter Application Number"
                        required
                    />
                    {appNumberError && <span className="errorapplication-message">{appNumberError}</span>}
                </div>

                <button type="submit" className="login-button">
                    {loading ? 'Starting...' : 'Start'}
                </button>

            </form>
        </div>
    );
}

export default StudentForm;
