import React, { useState, useEffect } from 'react';
import './Test.css'; // Ensure you have a CSS file to style the component
import axios from 'axios';

const Test = () => {
  const [questions, setQuestions] = useState([]);
  const [mcqQuestions, setMcqQuestions] = useState([]);
  const [descriptiveQuestions, setDescriptiveQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [timeLeft, setTimeLeft] = useState(3600); // 1 hour in seconds
  const [questionStates, setQuestionStates] = useState([]);
  const [selectedType, setSelectedType] = useState('mcq');
  const [showWarning, setShowWarning] = useState(false);
  // const [IsSaveNextClicked, setIsSaveNextClicked] = useState(false);
  const applicationNumber = localStorage.getItem('applicationnumber');
  const apiUrl = 'https://598sj81enf.execute-api.ap-south-1.amazonaws.com/v1/submitAllstudentquestion_M'; // Replace with your API URL

  // Load questions from localStorage on component mount
  // useEffect(() => {
  //   const storedQuestions = localStorage.getItem('shuffledQuestions');
  //   if (storedQuestions) {
  //     const parsedQuestions = JSON.parse(storedQuestions);
  //     const mcqQuestions = parsedQuestions.mcqQuizz || [];
  //     const descriptiveQuestions = parsedQuestions.descriptiveQuizz || [];

  //     if (selectedType === 'mcq') {
  //       setQuestions(mcqQuestions);
  //       setQuestionStates(Array(mcqQuestions.length).fill({ visited: false, answered: false, marked: false }));
  //     } else {
  //       setQuestions(descriptiveQuestions);
  //       setQuestionStates(Array(descriptiveQuestions.length).fill({ visited: false, answered: false, marked: false }));
  //     }
  //   }
  // }, [selectedType]);
  useEffect(() => {
    const storedQuestions = localStorage.getItem('shuffledQuestions');
    if (storedQuestions) {
      const parsedQuestions = JSON.parse(storedQuestions);
      const mcqQuestions = parsedQuestions.mcqQuizz || [];
      const descriptiveQuestions = parsedQuestions.descriptiveQuizz || [];

      setMcqQuestions(mcqQuestions);
      setDescriptiveQuestions(descriptiveQuestions);

      if (selectedType === 'mcq') {
        setQuestions(mcqQuestions);
        setQuestionStates(Array(mcqQuestions.length).fill({ visited: false, answered: false, marked: false }));
      } else if (selectedType === 'descriptive') {
        setQuestions(descriptiveQuestions);
        setQuestionStates(Array(descriptiveQuestions.length).fill({ visited: false, answered: false, marked: false }));
      }
    }
  }, [selectedType]);
  // Timer effect to count down time
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          handleSubmit(); // Handle submission when time runs out
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Set question states when questions change
  useEffect(() => {
    setQuestionStates(Array(questions.length).fill({ visited: false, answered: false, marked: false }));
  }, [questions]);


  // const handleNext = async () => {
  //   const questionId = questions[currentQuestion]._id;
  //   const storedItem = JSON.parse(localStorage.getItem(`question-${currentQuestion + 1}`)) || {};

  //   const updateArray = (arrayKey, updateItem) => {
  //     const array = JSON.parse(localStorage.getItem(arrayKey)) || [];
  //     const existingIndex = array.findIndex(item => item.questionId === questionId);

  //     if (existingIndex !== -1) {
  //       array[existingIndex] = updateItem;
  //     } else {
  //       array.push(updateItem);
  //     }

  //     localStorage.setItem(arrayKey, JSON.stringify(array));
  //   };

  //   try {
  //     if (storedItem && (storedItem.optionId || storedItem.optionDescription)) {
  //       if (selectedType === 'mcq') {
  //         updateArray('mcqArray', { questionId, optionId: storedItem.optionId });
  //         await submitQuestion(questionId, storedItem.optionId);

  //         if (currentQuestion < questions.length - 1) {
  //           setCurrentQuestion(currentQuestion + 1);
  //         }
  //       } else if (selectedType === 'descriptive') {
  //         updateArray('descriptiveArray', { questionId, answer: storedItem.optionDescription });
  //         await submitDescriptiveAnswer(questionId, storedItem.optionDescription);

  //         if (currentQuestion < questions.length - 1) {
  //           setCurrentQuestion(currentQuestion + 1);
  //         }
  //       }
  //     } else {
  //       const action = 'delete';
  //       if (selectedType === 'mcq') {
  //         await submitQuestion(questionId, null, action);
  //         updateArray('mcqArray', { questionId, optionId: null });
  //       } else if (selectedType === 'descriptive') {
  //         await submitDescriptiveAnswer(questionId, null, action);
  //         updateArray('descriptiveArray', { questionId, answer: null });
  //       }
  //     }
  //     setIsSaveNextClicked(true);
  //   } catch (error) {
  //     console.error('Error processing question:', error);
  //   }
  // };

  const handleNext = async () => {
    const questionId = questions[currentQuestion]._id;

    // Fetch stored MCQ and descriptive data separately
    const storedMCQItem = JSON.parse(localStorage.getItem(`mcq-${currentQuestion + 1}`)) || {};
    const storedDescriptiveItem = JSON.parse(localStorage.getItem(`descriptive-${currentQuestion + 1}`)) || {};

    const updateArray = (arrayKey, updateItem) => {
      const array = JSON.parse(localStorage.getItem(arrayKey)) || [];
      const existingIndex = array.findIndex(item => item.questionId === questionId);

      if (existingIndex !== -1) {
        array[existingIndex] = updateItem;
      } else {
        array.push(updateItem);
      }

      localStorage.setItem(arrayKey, JSON.stringify(array));
    };

    try {
      if (selectedType === 'mcq' && storedMCQItem.optionId) {
        updateArray('mcqArray', { questionId, optionId: storedMCQItem.optionId });
        await submitQuestion(questionId, storedMCQItem.optionId);

        if (currentQuestion < questions.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
        }
      } else if (selectedType === 'descriptive' && storedDescriptiveItem.optionDescription) {
        updateArray('descriptiveArray', { questionId, answer: storedDescriptiveItem.optionDescription });
        await submitDescriptiveAnswer(questionId, storedDescriptiveItem.optionDescription);

        if (currentQuestion < questions.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
        }
      } else {
        const action = 'delete';
        if (selectedType === 'mcq') {
          await submitQuestion(questionId, null, action);
          updateArray('mcqArray', { questionId, optionId: null });
        } else if (selectedType === 'descriptive') {
          await submitDescriptiveAnswer(questionId, null, action);
          updateArray('descriptiveArray', { questionId, answer: null });
        }
      }
      // setIsSaveNextClicked(true);
    } catch (error) {
      console.error('Error processing question:', error);
    }
  };



  // API Call to submit, change, or delete a MCQ question
  const submitQuestion = (questionId, optionId, action = '') => {
    const payload = {
      applicationNumber,
      questionId,
      optionId
    };
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    };

    if (!optionId) {
      payload.action = action || 'delete';
    }

    return axios.post(`${apiUrl}`, payload, headers)
      .then(response => {
        console.log('MCQ API Response:', response.data);
      })
      .catch(error => {
        console.error('MCQ API Error:', error);
        throw error;
      });
  };

  // API Call to submit, change, or delete a descriptive question answer
  const submitDescriptiveAnswer = (questionId, answer, action = '') => {
    const payload = {
      applicationNumber,
      questionId,
      answer
    };
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    };

    if (!answer) {
      payload.action = action || 'delete';
    }

    return axios.post(`${apiUrl}`, payload, headers)
      .then(response => {
        console.log('Descriptive API Response:', response.data);
      })
      .catch(error => {
        console.error('Descriptive API Error:', error);
        throw error;
      });
  };

  // Handle previous question
  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  // Mark/unmark question for review
  const handleMarkForReview = () => {
    if (currentQuestion < questions.length - 1) {
      // Increment currentQuestion
      setCurrentQuestion(currentQuestion + 1);
    }
    setQuestionStates(prevState => {
      const newState = [...prevState];
      newState[currentQuestion].marked = !newState[currentQuestion].marked;
      return newState;
    });
  };

  // Handle answering MCQ
  // const handleAnswer = (optionId, optionDescription) => {
  //   const storedItem = JSON.parse(localStorage.getItem(`question-${currentQuestion + 1}`)) || {};
  //   const questionId = questions[currentQuestion]._id;



  //   setQuestionStates(prevState => {
  //     const newState = [...prevState];
  //     newState[currentQuestion].answered = true;
  //     return newState;
  //   });

  //   localStorage.setItem(`question-${currentQuestion + 1}`, JSON.stringify({ questionId, optionId, optionDescription }));
  // };
  const handleAnswer = (optionId, optionDescription) => {

    const questionId = questions[currentQuestion]._id;

    if (optionId) {
      localStorage.setItem(`mcq-${currentQuestion + 1}`, JSON.stringify({ questionId, optionId }));
    } else if (optionDescription) {
      localStorage.setItem(`descriptive-${currentQuestion + 1}`, JSON.stringify({ questionId, optionDescription }));
    }
  };


  // Clear answer for the current question
  const handleClearResponse = () => {
    setQuestionStates(prevState => {
      const newState = [...prevState];
      newState[currentQuestion].answered = false;
      newState[currentQuestion].marked = false;
      return newState;
    });


    if (selectedType === 'mcq') {
      localStorage.removeItem(`mcq-${currentQuestion + 1}`);
      const mcqArray = JSON.parse(localStorage.getItem('mcqArray')) || [];
      const updatedMcqArray = mcqArray.filter(item => item.questionId !== questions[currentQuestion]._id);
      localStorage.setItem('mcqArray', JSON.stringify(updatedMcqArray));
    } else if (selectedType === 'descriptive') {
      localStorage.removeItem(`descriptive-${currentQuestion + 1}`);
      const descriptiveArray = JSON.parse(localStorage.getItem('descriptiveArray')) || [];
      const updatedDescriptiveArray = descriptiveArray.filter(item => item.questionId !== questions[currentQuestion]._id);
      localStorage.setItem('descriptiveArray', JSON.stringify(updatedDescriptiveArray));
    }
    setValue(''); // Ensure `value` state is reset
  };

  // Handle switch between MCQ and descriptive questions
  const handleTypeChange = type => {
    setSelectedType(type);
    setCurrentQuestion(0);
  };

  // Handle submit button click
  const handleSubmit = () => {
    setShowWarning(true);
  };

  const submitapiUrl = 'https://598sj81enf.execute-api.ap-south-1.amazonaws.com/v1/submitQuestionValidation_M';


  // Confirm submission
  const confirmSubmit = () => {
    setShowWarning(false);
    const mcqArray = JSON.parse(localStorage.getItem('mcqArray')) || [];
    const descriptiveArray = JSON.parse(localStorage.getItem('descriptiveArray')) || [];

    const allQuestions = [
      ...mcqArray.map(item => ({ questionId: item.questionId, optionId: item.optionId })),
      ...descriptiveArray.map(item => ({ questionId: item.questionId, answer: item.answer })),
    ];

    const payload = {
      applicationNumber,
      allQuestions
    };

    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    };

    axios.post(submitapiUrl, payload, headers)
      .then(response => {
        console.log('Submission API Response:', response.data);
        alert('Your answers have been submitted successfully.');
        // Additional logic after successful submission can be added here
      })
      .catch(error => {
        console.error('Submission API Error:', error);
        alert('Error submitting your answers. Please try again.');
      });

    // Additional submit logic can be added here
  };

  const renderMCQOptions = question => {
    console.log(question);
    const storedItemmcq = JSON.parse(localStorage.getItem(`mcq-${currentQuestion + 1}`)) || {};

    if (!question || !question.options || !Array.isArray(question.options)) {
      return <p>No options available for this question.</p>;
    }

    return (
      <div>
        <p>{question.question}</p>
        <ul className="options">
          {question.options.map(option => (
            <li key={option._id}>
              <input
                type="radio"
                name={`answer-${currentQuestion}`}
                id={option._id}
                value={option._id}
                onChange={() => handleAnswer(option._id)}
                checked={storedItemmcq.optionId === option._id}
              />
              <label htmlFor={option._id}>{option.answer}</label>
            </li>
          ))}
        </ul>
      </div>
    );
  };



  const [value, setValue] = useState('');

  useEffect(() => {
    const storedItemdescriptive = JSON.parse(localStorage.getItem(`descriptive-${currentQuestion + 1}`)) || {};
    setValue(storedItemdescriptive.optionDescription || '');
  }, [currentQuestion]);
  const renderDescriptiveQuestion = question => {


    const handleChange = (e) => {
      const newValue = e.target.value;
      setValue(newValue);
      localStorage.setItem(`descriptive-${currentQuestion + 1}`, JSON.stringify({ optionDescription: newValue }));
    };

    return (
      <div>
        <p>{question.question}</p>
        <textarea
          rows="4"
          cols="50"
          value={value}

          onChange={handleChange}
          style={{ border: '1px solid #ccc', borderRadius: '4px', outline: 'none' }}
        ></textarea>
      </div>
    );
  };





  // if (currentQuestion < 0 || currentQuestion >= questions.length) {
  //   return <div>Current question index out of bounds.</div>;
  // }

  return (
    <div className="container">
      <main className="main-content">
        <div className="question">
          <h2>Question No {currentQuestion + 1}</h2>
          {selectedType === 'mcq' ? renderMCQOptions(questions[currentQuestion]) : renderDescriptiveQuestion(questions[currentQuestion])}
          <div className="buttons">
            <button onClick={handlePrevious} disabled={currentQuestion === 0}>Previous</button>
            <button
              onClick={handleMarkForReview}
              className={questionStates[currentQuestion]?.marked ? 'marked' : ''}
            >
              {questionStates[currentQuestion]?.marked ? 'Unmark for Review' : 'Mark for Review'}
            </button>
            <button onClick={handleClearResponse}>Clear Response</button>
            <button onClick={handleNext} >Save & Next</button>
          </div>
        </div>

        <div className="sidebar">
          <div className="sections">
          <div>
      {mcqQuestions.length > 0 && (
        <button className="section-button" onClick={() => handleTypeChange('mcq')}>
          MCQ TYPE
        </button>
      )}
      {descriptiveQuestions.length > 0 && (
        <button className="section-button-des" onClick={() => handleTypeChange('descriptive')}>
          DESCRIPTIVE TYPE
        </button>
      )}
    </div>



            {/* <button className="section-button" onClick={() => handleTypeChange('mcq')}>MCQ TYPE</button>
            <button className="section-button" onClick={() => handleTypeChange('descriptive')}>DESCRIPTIVE TYPE</button> */}
          </div>
          <div className="time-left">
            <p>Time Left: {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}</p>
          </div>
          <div className="legend">
            <ul>
              <li><span className="answered"></span>Answered</li>
              <li><span className="not-answered"></span>Not Answered</li>
              <li><span className="marked"></span>Marked</li>
              <li><span className="not-visited"></span>Not Visited</li>
            </ul>
          </div>
          <div className="question-palette">

            <div className="question-palette">
              <ul className="question-list">
                {questions.map((question, index) => {
                  let className = '';




                  // if (questionStates[index]?.answered) {
                  //   className += ' answered';
                  // }

                  if (questionStates[currentQuestion]?.marked) {
                    className = ' marked';
                  }

                  // if (questionStates[index]?.saved) {
                  //   className += ' saved';
                  // }

                  return (
                    <li
                      key={question._id}
                      className={className.trim()} // Ensure no extra spaces
                      onClick={() => setCurrentQuestion(index)}
                    >
                      {index + 1}
                    </li>
                  );
                })}
              </ul>
            </div>



          </div>
          <div className="button-container">
            <div className="actions">
              <button className="action-button-ques">Question Paper</button>
              <button className="action-button" onClick={handleSubmit}>Submit</button>
            </div>
          </div>

          {showWarning && (
            <div className="modal-card-container">
              <div className="modal-overlay">
                <div className="modal">
                  <div className="modal-content">
                    <p>Are you sure you want to submit the exam?</p>
                    <div className="modal-buttons">
                      <button className='yesbutton' onClick={confirmSubmit}>Yes</button>
                      <button className='nobutton' onClick={() => setShowWarning(false)}>No</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
      </main>
    </div>
  );
};

export default Test;
